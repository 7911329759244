<template>
  <!--begin::PriceSearchPurchaseOK-->
  <div class="p-12" v-if="!Loading">
    <div class="white-box shadow-box p-9">
      <div class="row">
        <!--begin::Confirmation header-->
        <div class="form-group col-lg-12 text-center">
          <h1 class="text-primary font-weight-bolder">
            {{ $t("PRICE_SEARCH.PURCHASE_OK.THANK_YOU") }}
          </h1>
          <p>
            {{ $t("PRICE_SEARCH.PURCHASE_OK.THANK_YOU_INFO_1") }}
            <br />
            {{ $t("PRICE_SEARCH.PURCHASE_OK.THANK_YOU_INFO_2") }}
          </p>
          <p>
            <strong>{{ $t("GENERIC.CREATION_DATE") }}:</strong>
            {{ formatCreationDateTime() }}
            <span
              class="ml-12 d-print-none"
              style="cursor: pointer"
              onclick="window.print();"
            >
              <inline-svg
                src="/media/icons/print-alt-1-primary.svg"
                class="mr-3"
              />
              <u>{{ $t("GENERIC.PRINT") }}</u>
            </span>
          </p>
        </div>
        <!--end::Confirmation header-->

        <!--begin::Confirmation origin-->
        <div class="form-group col-lg-4">
          <label>
            <inline-svg src="/media/icons/pin-1-primary.svg" class="mr-3" />
            {{ $t("PRICE_SEARCH.STEP1.ORIGIN") }}
          </label>
          <br />
          <strong>{{ $t("PRICE_SEARCH.STEP1.ORIGIN_ADDRESS") }}</strong>
          <br />
          {{ ShipmentData.OriginAddress.TradeName }}
          <br />
          {{ ShipmentData.OriginAddress.Street }}
          <br />
          {{
            ShipmentData.OriginAddress.PostalCode +
              " - " +
              ShipmentData.OriginAddress.City +
              " (" +
              ProvinceNames[ShipmentData.OriginAddress.ProvinceID] +
              ")"
          }}
          <br />
          <strong>{{ $t("PRICE_SEARCH.STEP3.EXTRA_DATA.CONTACT") }}</strong>
          <br />
          {{ ShipmentData.OriginAddress.ContactName }}
          <br />
          <a :href="'mailto:' + ShipmentData.OriginAddress.ContactEmail">
            {{ ShipmentData.OriginAddress.ContactEmail }}
          </a>
          <br />
          {{ ShipmentData.OriginAddress.ContactPhone }}
        </div>
        <!--end::Confirmation origin-->

        <!--begin::Confirmation destination-->
        <div class="form-group col-lg-4">
          <label>
            <inline-svg src="/media/icons/pin-1-primary.svg" class="mr-3" />
            {{ $t("PRICE_SEARCH.STEP1.DESTINATION") }}
          </label>
          <br />
          <strong>{{ $t("PRICE_SEARCH.STEP1.DESTINATION_ADDRESS") }}</strong>
          <br />
          {{ ShipmentData.DestinationAddress.TradeName }}
          <br />
          {{ ShipmentData.DestinationAddress.Street }}
          <br />
          {{
            ShipmentData.DestinationAddress.PostalCode +
              " - " +
              ShipmentData.DestinationAddress.City +
              " (" +
              ProvinceNames[ShipmentData.DestinationAddress.ProvinceID] +
              ")"
          }}
          <br />
          <strong>{{ $t("PRICE_SEARCH.STEP3.EXTRA_DATA.CONTACT") }}</strong>
          <br />
          {{ ShipmentData.DestinationAddress.ContactName }}
          <br />
          <a :href="'mailto:' + ShipmentData.DestinationAddress.ContactEmail">
            {{ ShipmentData.DestinationAddress.ContactEmail }}
          </a>
          <br />
          {{ ShipmentData.DestinationAddress.ContactPhone }}
        </div>
        <!--end::Confirmation destination-->

        <!--begin::Confirmation other-->
        <div class="form-group col-lg-4">
          <label>
            {{ $t("PRICE_SEARCH.STEP3.FINISH.OTHER_DATA") }}
          </label>
          <br />
          <strong>
            {{ $t("PRICE_SEARCH.STEP3.FINISH.INVOICE_CENTER") }}
          </strong>
          <br />
          {{ InvoiceCenterNick }}
          <br />
          <strong>{{ $t("PRICE_SEARCH.STEP3.FINISH.OBSERVERS") }}</strong>
          <br />
          {{ ObserversNick }}
        </div>
        <!--end::Confirmation other-->

        <!--begin::Confirmation route-->
        <div
          class="col-lg-12 px-0"
          v-for="whichLeg in ShipmentData.Back.Enabled
            ? ['There', 'Back']
            : ['There']"
          :key="'Confirmation_' + whichLeg"
        >
          <div class="text-primary font-weight-bolder text-center">
            <h2 v-if="whichLeg === 'There'">
              {{
                ShipmentData.Back.Vehicles.length > 0
                  ? $t("PRICE_SEARCH.STEP1.THERE").toUpperCase()
                  : $t("PRICE_SEARCH.STEP1.JUST_THERE").toUpperCase()
              }}
            </h2>
            <h2 v-else>
              {{ $t("PRICE_SEARCH.STEP1.BACK").toUpperCase() }}
            </h2>
          </div>

          <!--begin::Confirmation dates-->
          <div class="row">
            <div class="form-group col-lg-4">
              <label>
                {{ $t("PRICE_SEARCH.STEP3.EXTRA_DATA.ORIGIN_DATE_DESIRED") }}
              </label>
              <br />
              {{ formatOriginDate(whichLeg) }}
            </div>
            <div class="form-group col-lg-4">
              <label>
                {{ $t("PRICE_SEARCH.STEP1.DESTINATION_DATE_DESIRED") }}
              </label>
              <br />
              {{ formatDestinationDate(whichLeg) }}
            </div>
            <div class="form-group col-lg-4">
              <label>
                {{
                  $t(
                    "PRICE_SEARCH.STEP3.EXTRA_DATA.DESTINATION_DATE_DESIRED_TIME"
                  )
                }}
              </label>
              <br />
              {{ formatDestinationTime(whichLeg) }}
            </div>

            <div
              v-if="
                ShipmentData[whichLeg].Notes &&
                  ShipmentData[whichLeg].Notes.length > 0
              "
              class="form-group col-lg-12"
            >
              <label>
                {{ $t("PRICE_SEARCH.STEP3.EXTRA_DATA.NOTES") }}
              </label>
              <br />
              {{ ShipmentData[whichLeg].Notes }}
            </div>
          </div>
          <!--end::Confirmation dates-->

          <!--begin::Confirmation vehicles-->
          <div class="row">
            <div class="form-group col-lg-12">
              <label>
                <inline-svg src="/media/icons/car-primary.svg" class="mr-3" />
                {{
                  $t("PRICE_SEARCH.STEP1.VEHICLES") +
                    " (" +
                    ShipmentData[whichLeg].Vehicles.length +
                    ")"
                }}
              </label>
              <div
                v-for="(vehicle, v) in ShipmentData[whichLeg].Vehicles"
                :key="'Confirmation_' + whichLeg + '_Vehicle_' + v"
                class="mb-3"
              >
                <strong>{{ vehicle.Plate }}</strong>
                -
                {{
                  vehicle.Brand +
                    ", " +
                    vehicle.Model +
                    ", " +
                    vehicle.Version +
                    ", " +
                    vehicle.Year +
                    ". " +
                    vehicle.VehicleTypeName +
                    ", " +
                    vehicle.VehicleStatusName
                }}.
                <div
                  v-for="whichSideServices in ['Origin', 'Destination']"
                  :key="
                    whichLeg +
                      '_Vehicle_' +
                      v +
                      '_Services_' +
                      whichSideServices
                  "
                >
                  <span class="pl-9">
                    {{
                      $t(
                        "PRICE_SEARCH.STEP3.EXTRA_DATA.SERVICES_" +
                          whichSideServices.toUpperCase()
                      )
                    }}:
                  </span>
                  <span
                    v-if="
                      !vehicle['Services' + whichSideServices] ||
                        vehicle['Services' + whichSideServices].length === 0
                    "
                  >
                    {{ $t("GENERIC.N_D") }}.
                  </span>
                  <span v-else>
                    {{ vehicle["Services" + whichSideServices].join(", ") }}.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!--end::Confirmation vehicles-->
        </div>
        <!--end::Confirmation route-->

        <!--begin::Confirmation price-->
        <div class="form-group col-lg-12 border-top-200 pt-6">
          <h3 class="font-weight-bolder">
            {{ $t("PRICE_SEARCH.PURCHASE_OK.OFFER_ABSTRACT") }}
          </h3>
        </div>
        <div class="form-group col-lg-6">
          <label>
            <inline-svg src="/media/icons/truck-primary.svg" class="mr-3" />
            {{ $t("PRICE_SEARCH.PURCHASE_OK.TRANSPORTER") }}
          </label>
          <br />
          <strong>{{ ShipmentData.Transporter.FiscalName }}</strong>
          <br />
          {{
            $t("COMPANIES.FISCAL_ID_SHORT") +
              " " +
              ShipmentData.Transporter.FiscalID
          }}
          <br />
          {{ ShipmentData.Transporter.Street }}
          <br />
          {{
            ShipmentData.Transporter.PostalCode +
              " - " +
              ShipmentData.Transporter.City +
              " (" +
              ProvinceNames[ShipmentData.Transporter.ProvinceID] +
              ")"
          }}
          <br />
          <a :href="'mailto:' + ShipmentData.Transporter.ContactEmail">
            {{ ShipmentData.Transporter.ContactEmail }}
          </a>
          <br />
          {{ ShipmentData.Transporter.ContactPhone }}
        </div>

        <div class="col-lg-6 p-9">
          <div class="font-weight-bolder">
            {{
              $t("PRICE_SEARCH.STEP2.FARE") +
                " " +
                $t("FARE_TYPE_NAMES_BY_ID." + ShipmentData.FareTypeID)
            }}
          </div>

          <div
            class="w-100 d-flex align-items-baseline justify-content-between"
          >
            <div class="text-primary priceLine">
              <strong>
                {{ $t("PRICE_SEARCH.STEP3.TOTAL").toUpperCase() }}
              </strong>
            </div>
            <div>
              <div class="text-primary priceTotal">
                {{ formatPrice() }}
              </div>
            </div>
          </div>
        </div>
        <!--end::Confirmation price-->

        <!--begin::Confirmation continue button-->
        <div class="col-lg-12 my-3 text-center">
          <button
            class="btn btn-pill btn-white button-form button-form-primary"
            @click="onClickContinueButton"
          >
            {{ $t("GENERIC.CONTINUE") }}
          </button>
        </div>
        <!--end::Confirmation continue button-->

        <!--begin::Upgrade button-->
        <div
          v-if="!currentUserCompanyIsPremium"
          class="col-lg-12 my-3 text-center"
        >
          <p class="text-dark">
            {{ $t("HOME.UPGRADE") }}
          </p>
          <button
            class="btn btn-pill btn-tertiary button-form"
            @click="$router.push('/manager/mysubscription')"
          >
            {{ $t("MY_COMPANY.CHANGE_SUBSCRIPTION") }}
          </button>
        </div>
        <!--end::Upgrade button-->
      </div>
    </div>
  </div>
  <!--end::PriceSearchPurchaseOK-->
</template>

<style scoped>
.priceLine {
  font-size: 1.2em;
  font-weight: 800;
}
.priceTotal {
  font-size: 1.75em !important;
  font-weight: 800;
}
</style>

<script>
import { mapGetters } from "vuex";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import moment from "moment";
import i18nService from "@/core/services/i18n.service.js";

import InvoiceCenterService from "@/core/services/api/v2/invoicecenter.service";
import PriceSearchService from "@/core/services/api/v2/pricesearch.service";
import ProvinceService from "@/core/services/api/v2/province.service";
import ShipmentService from "@/core/services/api/v2/shipment.service";
import UserService from "@/core/services/api/v2/user.service";

export default {
  name: "PriceSearchPurchaseOK",

  data() {
    return {
      Loading: true,
      ShipmentID: 0,
      ShipmentData: {},
      ProvinceNames: [],
      InvoiceCenterNick: "",
      ObserversNick: "",
      DateFormatEN: "YYYY-MM-DD",
      DateFormatES: "DD/MM/YYYY",
      DateFormatLang: "DD/MM/YYYY"
    };
  },

  computed: {
    ...mapGetters(["currentUserCompanyIsPremium"])
  },

  created() {
    // Pick format for dates
    let userLang = i18nService.getActiveLanguage();
    if (userLang === "en") {
      this.DateFormatLang = this.DateFormatEN;
    } else {
      this.DateFormatLang = this.DateFormatES;
    }
  },

  mounted() {
    if (!this.$route.params.shipmentID) {
      this.onClickContinueButton();
    } else {
      // Show page loader
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      this.ShipmentID = parseInt(this.$route.params.shipmentID);
      this.loadProvinceNames();
    }
  },

  methods: {
    loadProvinceNames() {
      this.Loading = true;
      this.ProvinceNames = [];

      ProvinceService.listAll().then(response => {
        response.forEach(p => {
          this.ProvinceNames[p.ProvinceID] = p.Name;
        });

        this.loadOfferData();
      });
    },

    loadOfferData() {
      ShipmentService.getAtOffer(this.ShipmentID).then(response => {
        if (response === 401 || response === 404) {
          // Remove page loader
          this.Loading = false;
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");

          this.onClickContinueButton();
        } else {
          this.ShipmentData = response;

          // Load InvoiceCenter nick
          this.InvoiceCenterNick = this.$t("GENERIC.N_D");
          InvoiceCenterService.listAll().then(response => {
            for (let i = 0; i < response.length; i++) {
              let maybeIC = response[i];
              if (
                maybeIC.InvoiceCenterID === this.ShipmentData.InvoiceCenterID
              ) {
                this.InvoiceCenterNick = maybeIC.Nick;
                break;
              }
            }

            // Load Observers list
            this.ObserversNick = "";
            UserService.listAllObservers(false).then(response => {
              let nicks = [];
              response.forEach(o => {
                if (this.ShipmentData.Observers.includes(o.UserID)) {
                  nicks.push(o.Nick + " (" + o.NickCompany + ")");
                }
              });

              this.ObserversNick =
                nicks.length > 0
                  ? nicks.join(", ") + "."
                  : this.$t("GENERIC.N_D");

              // Remove page loader
              this.Loading = false;
              this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
            });
          });
        }
      });
    },

    formatCreationDateTime() {
      return moment(this.ShipmentData.CreatedAt).format(
        this.DateFormatLang + " HH:mm"
      );
    },

    formatOriginDate(whichLeg) {
      let whichDate = this.ShipmentData[whichLeg].OriginDateDesired;

      if (whichLeg === "Back" && parseInt(whichDate.substring(0, 4)) < 2000) {
        // Special value for "when There arrives"
        return this.$t("OFFERS.COMMON.ORIGIN_DATE_DESIRED_BACK_THERE");
      } else {
        return moment(whichDate).format(this.DateFormatLang);
      }
    },

    formatDestinationDate(whichLeg) {
      let dateFrom = this.ShipmentData[whichLeg].DestinationDateDesiredFrom;
      let dateTo = this.ShipmentData[whichLeg].DestinationDateDesiredTo;

      if (dateFrom === "" && dateTo === "") {
        return this.$t("PRICE_SEARCH.STEP1.FLEXIBLE");
      } else if (dateFrom === dateTo) {
        return moment(dateFrom).format(this.DateFormatLang);
      } else {
        return (
          moment(dateFrom).format(this.DateFormatLang) +
          " - " +
          moment(dateTo).format(this.DateFormatLang)
        );
      }
    },

    formatDestinationTime(whichLeg) {
      let timeFrom = this.ShipmentData[whichLeg].DestinationDateDesiredTimeFrom;
      let timeTo = this.ShipmentData[whichLeg].DestinationDateDesiredTimeTo;

      return timeFrom.substring(0, 5) + " - " + timeTo.substring(0, 5);
    },

    formatPrice() {
      let price = this.ShipmentData.Price.toFixed(2);
      let userLang = i18nService.getActiveLanguage();
      if (userLang === "en") {
        return price + " " + this.ShipmentData.Currency;
      } else {
        return price.replace(".", ",") + " " + this.ShipmentData.Currency;
      }
    },

    onClickContinueButton() {
      // Go to "My Sent Offers"
      PriceSearchService.cleanCachedLists();
      this.$router.push("/manager/offers");
    }
  }
};
</script>
